<template>
  <div class="chating">
    <div class="header">
      <p class="doctorName" @click="isShowDoc = !isShowDoc">
        医生名称
        <van-icon name="arrow-down" color="#42CEC8" v-if="!isShowDoc" />
        <van-icon name="arrow-up" color="#42CEC8" v-else />
      </p>
      <!-- v-if="orderStatus == 2" -->
      <div class="title" v-if="orderStatus == 1">
        <p v-if="isShowBtn" @click="onBack">返回主页</p>
        等待医生回复
      </div>
      <div class="title" v-if="orderStatus == 2 || orderStatus == 3">
        <p v-if="isShowBtn" @click="onBack">返回主页</p>
        {{hours}}后或{{expriceNum}}次对话后问题关闭
      </div>
      <div class=" title" v-if="orderStatus > 3">
        <p v-if="isShowBtn" @click="onBack">返回主页</p>
        问题已关闭
      </div>
      <!-- <div class="title">
        23小时或20次对话后问题关闭
      </div> -->
      <div class="mask" @click="isShowDoc = false" v-if="isShowDoc"></div>
      <div class="doc_info" @click="onDetail" v-if="isShowDoc">
        <img v-if="docDetail.doctorHeadImg" :src="docDetail.doctorHeadImg" alt="">
        <img v-else src="../assets/img/touxiang.png" alt="">
        <div class="info">
          <div class="info_right">
            <p>
              <span>{{docDetail.doctorName}}</span>
              <span>{{docDetail.doctorHospitalName}}</span>
            </p>
          </div>
          <div class="hospital">
            <p>{{docDetail.doctorClinic}}</p>
            <p>{{docDetail.doctorProfessionalTitle}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="content" id="contentId" ref="content" :style="{'height': chatingHeight}" @click="onBlur">
      <div class="complaint">
        <div class="patient">
          <p>
            <span class="name">就诊人：{{complaint && complaint.userName}}</span>
            <span class="sex">{{complaint && complaint.userSex}}</span>
            <span class="age">{{complaint && complaint.userAge}}岁</span>
          </p>
          <van-icon name="arrow-down" size="0.3rem" color="#42CEC8" @click="isShowComplaint = false" v-if="isShowComplaint" />
          <van-icon name="arrow-up" size="0.3rem" color="#42CEC8" @click="isShowComplaint = true" v-else />
        </div>
        <div class="complaint_content" v-if="isShowComplaint">
          <p>{{complaint && complaint.content}}</p>
          <div class="complaint_img">
            <div class="main_img" v-if="complaint && complaint.imgUrl" v-for="item in complaint && complaint.imgUrl">
              <img :src="item" alt="" @click.stop="onPrediv(item)">
            </div>
          </div>
        </div>
      </div>
      <p class="title">您好，请稍后，医生正在查看您的问诊情况，如还有其他问诊问题，请输入您的问题，医生会尽快回复您。</p>
      <p class="title" v-if="showWaitTip">请稍后，医生正在查看您的问诊情况。</p>
      <div v-for="(item, index) in chatRecord" :key="item.id" style="margin-top: 0.4rem">
        <div v-if="item.fromUserType == 1">
          <div class="record_time" v-if="item.time">{{item.time}}</div>
          <div class="user">
            <div class="describe" v-if="item.msgContentType == 1">{{item.content}}</div>
            <img class="img" v-if="item.msgContentType == 2" @click="onPrediv(item)" :src="item.content" alt="">
            <div class="drug_box"  v-if="item.msgContentType == 15">
              <div class="t">您好，以下是医生开的药</div>
              <div class="drug_item" v-for="(items, indexs) in item.content.drugList" v-if="items.medicine_info">
                <img class="drug_img" :src="items.medicine_info.thumb_url" alt="">
                <div class="texts">
                  <div class="t1">{{items.medicine_info.name}}</div>
                  <div class="ts"><span class="money">￥{{items.medicine_info.selling_price}}</span><span class="unit">{{items.medicine_info.specification}}/{{items.num}}{{items.medicine_info.packing}}</span></div>
                </div>
              </div>
              <div class="btns">
                <div class="b">已确认</div>
              </div>
            </div>
            <!-- <img class="user_icon" src="../assets/img/touxiang.png" alt=""> -->
          </div>
        </div>
        <div v-if="item.fromUserType == 2">
          <div class="record_time" v-if="item.time">{{item.time}}</div>
          <div class="doctor">
            <img class="user_icon" v-if="docDetail.doctorHeadImg" :src="docDetail.doctorHeadImg" alt="">
            <img class="user_icon" v-else src="../assets/img/touxiang.png" alt="">
            <div class="describe" v-if="item.msgContentType == 1">{{item.content}}</div>
            <img class="img" v-if="item.msgContentType == 2" @click="onPrediv(item)" :src="item.content" alt="">
            <!-- <audio :src="item.content" class="audio" v-if="item.sessionType == 3" controls="controls"></audio> -->
            <!-- <div class="audio" v-if="item.sessionType == 3" @click="onPlay(item)">
              <img src="../assets/img/audio.png"></img>
            </div> -->
            <xaudio v-if="item.msgContentType == 3" :src="item.content" />
            <div class="drug_box"  v-if="item.msgContentType == 15">
              <div class="t">您好，以下是医生开的药</div>
              <div class="drug_item" v-for="(items, indexs) in item.content.drugList" v-if="items.medicine_info">
                <img class="drug_img" :src="items.medicine_info.thumb_url" alt="">
                <div class="texts">
                  <div class="t1">{{items.medicine_info.name}}</div>
                  <div class="ts"><span class="money">￥{{items.medicine_info.selling_price}}</span><span class="unit">{{items.medicine_info.specification}}/{{items.num}}{{items.medicine_info.packing}}</span></div>
                </div>
              </div>
              <div class="btns">
                <div class="b" @click="onCheckDrugOrder(item.content)">确定</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="evaluate_end" v-if="orderStatus > 3 && orderStatus == 4 && !isComment">
        <p>您对医生的本次服务还满意吗？</p>
        <button @click="show = true">评价</button>
      </div>
      <div class="evaluate_end" v-if="orderStatus > 3 && orderStatus == 4 && isComment">
        <p>感谢您对医生的支持</p>
        <button @click="lookToDocEvaluate">查看评价</button>
      </div>
      <div id="end" ref="end" style="visibility:hidden;"></div>
    </div>
    <div class="fixed_footer">
      <div class="footer" v-if="orderStatus ==2 || orderStatus == 3">
        <input type="file" style="display: none" @change="getFile" ref="img_file">
        <img src="../assets/img/photo_icon.png" @click="upFile" alt="">
        <!-- <textarea type="text"> -->
        <textarea class="input" placeholder="请输入内容" ref="textarea" v-model.trim="chatValue"></textarea>
        <button @click="sendMsg" class="sendBtn">发送</button>
      </div>
      <div class="footer" v-if="orderStatus > 3 && orderStatus == 4">
        <div>
          <h4>本次问诊已结束</h4>
        </div>
      </div>
      <!-- v-if="orderStatus > 3 && orderStatus == 7" -->
      <!-- <div class="footer" v-if="orderStatus > 3 && orderStatus == 4 && (!isComment)">
        <div class="bottom_item">
          <h4>问题已自动关闭</h4>
          <div class="footer_btn">
            <button class="btn" @click="toEvaluation">评论本次服务</button>
            <button class="btn" @click="toVisits">继续咨询</button>
          </div>
        </div>
      </div> -->
    </div>

    <!-- 评价弹出框 -->
    <van-popup v-model="show" position="bottom" round closeable>
      <div class="evaluation" v-if="!isEvaluation">
        <div class="evaluation_header">
          <img src="../assets/img/touxiang.png" />
          <div>请对我的服务进行评价</div>
        </div>
        <div class="evaluation_footer">
          <div class="health_doctor">
            <div class="title">医生</div>
            <div class="name">{{docDetail.doctorName}}</div>
          </div>
          <div class="evaluation_item">
            <div class="item_type">服务态度</div>
            <van-rate v-model="serviceAttitude" data-str="serviceAttitude" size=".54rem" color="#FFB154" void-color="#eee" :readonly="isComment" void-icon="star"></van-rate>
          </div>
          <div class="evaluation_item">
            <div class="item_type">服务专业度</div>
            <van-rate v-model="serviceProfessionalism" data-str="serviceProfessionalism" size=".54rem" color="#FFB154" :readonly="isComment" void-color="#eee" void-icon="star"></van-rate>
          </div>
          <div class="evaluation_item">
            <div class="item_type">服务响应速度</div>
            <van-rate v-model="serviceResponseSpeed" data-str="serviceResponseSpeed" size=".54rem" color="#FFB154" :readonly="isComment" void-color="#eee" void-icon="star"></van-rate>
          </div>
          <div class="textarea">
            <textarea v-model="textarea" bindinput="changeText" :rows="3" placeholder="其他想说的（意见或建议）" :disabled="isComment" placeholder-syle="color: #a7a7a7; font-size: 26rem;"></textarea>
            <!-- <textarea placeholder="placeholder颜色是红色的" placeholder-style="color:red;"  /> -->
          </div>
        </div>
        <div class="btn" @click="evaluateSubmit" v-if="!isComment">提交</div>
      </div>
      <div class="evaluation_success" v-if="isEvaluation">
        <img src="../assets/img/success1.png" />
        <div class="success">评价成功</div>
        <div class="tips">感谢您的评价，祝您生活愉快</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import bus from '../utils/bus';
import { isWeiXin, getQueryVariable } from '@/utils/util';
import BASE_URL from '@/request/base';
// import { Toast, ImagePrediv } from 'vant';
import { Toast, ImagePreview } from 'vant';
import xaudio from '@/components/audio';
import { getDate, getTimeText } from '../utils/util';
import { setTimeout } from 'timers';
export default {
  components: {
    xaudio
  },
  data() {
    return {
      isEvaluation: false,
      textarea: '',  //评价内容
      serviceAttitude: 0, //服务态度星级
      serviceProfessionalism: 0, //服务专业度
      serviceResponseSpeed: 0, //服务响应速度
      show: false, //显示评价弹出框
      chatValue: '',
      problemId: null,
      socket: null,
      doctorId: null,
      orderInfo: null, //订单信息
      isShowComplaint: true, //是否显示患者主诉
      docDetail: {},
      chatRecord: [],
      isClose: false,
      orderStatus: null,
      interrogationId: null,
      interrogationName: null,
      chatingHeight: null,
      height: null,
      isBottom: false,
      expriceNum: 20,
      templateType: null,
      hours: '24小时',
      isShowBtn: true,
      isComment: false, //是否评价
      websockTimer: null,
      isShowDoc: false,
      originalHeight: null,
      showWaitTip: false,
      currentAudio: null,
      complaint: null, //患者主诉
      memberInfo: JSON.parse(localStorage.getItem('userData')) || ''
    }
  },
  created() {
    // console.log('this.$route===>', this.$route)
    if (this.$route.query.isShowBtn) { //为true就不显示按钮
      this.isShowBtn = false;
    } else {
      this.isShowBtn = true;
    }
    this.templateType = this.$route.query.templateType;
    this.problemId = this.$route.query.problemId;
    this.doctorId = this.$route.query.doctorId;
    let _this = this;
    document.onkeydown = function (e) {
      var key = window.event.keyCode;

      if (key == 13) {
        _this.sendMsg();
        // lett.enterSearchMember();

      }

    }
  },
  mounted() {
    // // console.log('initWebSocket=============', initWebSocket)
    this.chatingRecord(this.$route.query.problemId);
    this.getPatientMain(this.$route.query.problemId); //查询患者主诉接口
    this.initOnMessage();
    this.getDocDetail(this.$route.query.doctorId);
    this.selectByOrderId(this.$route.query.problemId);
    this.computedHeight();
    var _this = this;
    let os = navigator.userAgent;
    _this.originalHeight = document.documentElement.clientHeight || document.body.clientHeight;
    // console.log('sdfs', os)
    // console.log('111111111111111111')
    // console.log('indexOf====>', os.indexOf('Android'))
    if (os.indexOf('Android') > -1) {
      window.onresize = function () {
        // console.log('rer')
        // var _this = this;
        //键盘弹起与隐藏都会引起窗口的高度发生变化
        var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;

        // console.log('键盘弹起==》', _this.originalHeight, windowHeight)
        if (windowHeight - 0 < _this.originalHeight - 0) {  //键盘弹起
          // console.log('键盘弹起进来')
          // setTimeout(() => {
          _this.initHeight(1);
          // }, 1000)
        } else {
          // console.log('键盘收起进来')
          // _this.isShowDoc = true;
          _this.initHeight(2);
        }

      }
    }
  },
  beforeDestroy() {
    this.eliminateSpot(this.problemId);
  },
  methods: {
    onCheckDrugOrder(content) { // 确认购买药品
      let wxapp = content.callbackJson.wxapp_pages_url.split('?');
      let url = `/pages/to_other_app?wxapp_pages_url=${wxapp[0]}&${wxapp[1]}&wxapp_app_id=${content.callbackJson.wxapp_app_id}&orderId=${this.orderInfo.orderId}`;
      // console.log(url);
      wx.miniProgram.getEnv(function (resData) {//获取当前环境
        if(resData.miniprogram) { // true 在微信小程序中
          wx.miniProgram.navigateTo({
            url: url, //跳转回小程序的页面
            success: function(){
              // console.log('success');
              // self.timer = setInterval(() => {
              //   self.selectByOrderId(res.data.data.orderId);
              // }, 2000)
            },
            fail: function(){
              // console.log('fail');
            }
          })
        }
      })
      return;
      let userData = JSON.parse(localStorage.getItem('userData')) || '';
      let params = {
        sysType: 2, //1药康夫 2在线医生
        toUserType: 2,  //接受者类型  1患者 2医生
        fromUserType: 1, //发送者类型 1患者角色 2医生角色
        fromUserId: userData.memberId, // 用户ID
        toUserId: this.doctorId,  //接受人id
        consultOrderId: this.problemId, //订单id
        msgContent: JSON.stringify(content), //发送内容
        msgContentType: 15, // 1-文字 2-图片 3-语言
        templateType: this.templateType,
        sessionType: 2 // 1回执通知 2会话 3订单通知 4心跳 5关闭 6通知用户已接单 7转单通知 8用户离线通知 11剩余次数通知
      }
      // console.log('确定推送药品', params);
      this.$socket.sendSock(params)
      params.content = JSON.parse(params.msgContent);
      this.chatRecord.push(params);
      this.$nextTick(() => {
        this.$refs.end.scrollIntoView(false);
      //   this.$refs.textarea.focus();
      //   document.documentElement.scrollTop = document.body.scrollHeight;
      //   document.body.scrollTop = document.body.scrollHeight;
      })
    },
    onBlur() {
      const isIphone = navigator.userAgent.toUpperCase().includes('IPHONE')  // 判断ios环境
      if (isIphone && this.$refs.textarea) {
        this.$refs.textarea.blur();
      }
    },
    // 清除未读消息数量
    eliminateSpot(orderId) {
      this.$http('post', `/server/problemOrder/setUnread/${orderId}/1`, {}, 1).then(res => {
        // console.log('清除未读消息数量====》', res);
      })
    },
    // 查询患者主诉接口
    getPatientMain(problemId) {
      this.$http('get', '/server/onlineDoctorProblemRecord/getMajorProblemRecord/' + problemId, {}, 1).then(res => {
        // console.log('患者主诉', res);
        if (res.data.code == 200) {
          this.complaint = res.data.data;
          if (this.complaint.imgUrl) {
            this.complaint.imgUrl = this.complaint.imgUrl.split(',');
          }
        }
      })
    },
    // 接受信息
    initOnMessage() {
      this.$socket.onMessage(data => {
        data.content = data.msgContent;
        if (data.consultOrderId != this.problemId) return;
        if (data.sessionType == 2) {  //接收信息通知
          // console.log('接受信息====>', data);
          if(data.msgContentType == 15) {
            data.content = JSON.parse(data.content);
          }
          this.chatRecord.push(data);
          this.$nextTick(() => {
            this.$refs.end.scrollIntoView(false);
          })
        } else if (data.sessionType == 11) {  //剩余次数通知
          // console.log('剩余次数通知====>', data);
          this.expriceNum = data.msgContent;
        } else if (data.sessionType == 5) { //会话关闭通知
          // console.log('会话关闭通知====>', data);
          this.orderStatus = 4
          this.selectByOrderId(this.problemId);
        } else if (data.sessionType == 12) { //当医生接诊在四分30秒未回复推送
          // console.log('当医生接诊在四分30秒未回复推送====>', data);
          this.showWaitTip = true;
        } else if (data.sessionType == 8) {
          this.onMessageCallBack(data);
        }
      })
    },
    onMessageCallBack(params) {
      params.sessionType = 2;
      params.templateType = this.templateType;
      params.fromUserId = this.memberInfo.memberId;
      params.fromUserType = params.userType;
      params.toUserId = this.doctorId;
      params.toUserType = 2;
      this.$http('post', '/server/webSocket/onMessageCallBack', params, 1).then(res => {
        // console.log('离线缓存消息', res);
      }).catch(res => {
      })
    },
    // 对医生进行评价
    evaluateSubmit() {
      Toast.loading({
        message: '提交中...',
        forbidClick: true,
        duration: 0
      });
      let params = {
        memberId: this.memberInfo.memberId,
        doctorId: this.doctorId,
        orderNum: this.orderInfo.orderId,
        interrogationPeople: this.interrogationName,
        consultType: 1,
        serviceAttitude: this.serviceAttitude,
        serviceProfessional: this.serviceProfessionalism,
        serviceResponseSpeed: this.serviceResponseSpeed,
        attentionContent: this.textarea
      }
      this.$http('post', '/server/doctorEstimateInfo/addDoctorEstimateInfo', params, 1).then(res => {
        if (res.data.code == 200) {
          Toast.clear();
          this.isEvaluation = true;
          this.isComment = true;
        } else {
          Toast(res.data.msg);
        }
      })
    },
    // 查看对医生得评价
    lookToDocEvaluate() {
      this.show = true;
      this.isEvaluation = false;
      let params = {
        orderId: this.orderInfo.orderId
      }
      this.$http('get', '/server/doctorEstimateInfo/getDoctorEstimateInfoByOrderId', params, 1).then(res => {
        if (res.data.code == 200) {
          this.interrogationPeople = res.data.data.interrogationPeople;
          this.textarea = res.data.data.attentionContent;
          this.serviceAttitude = res.data.data.serviceAttitude;
          this.serviceProfessionalism = res.data.data.serviceProfessional;
          this.serviceResponseSpeed = res.data.data.serviceResponseSpeed;
        } else {
          Toast(res.data.msg);
        }
      })
    },
    initHeight(type) {
      let _this = this;
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      let headerHeight = ''
      let footerHeight = ''
      let end = document.getElementById('end');
      if (type == 1) {
        setTimeout(() => {
          _this.height = windowHeight - 80;
          _this.chatingHeight = windowHeight + 'px';
          let contentId = document.getElementById('contentId')
          end.style.height = 70 + 'px';
          _this.$nextTick(() => {
            _this.$refs.end.scrollIntoView(false);
          })
        }, 500)
        // console.log('页面高度', _this.chatingHeight);
      } else {
        end.style.height = 1 + 'px';
        headerHeight = document.getElementsByClassName('header')[0].offsetHeight + 20;
        footerHeight = document.getElementsByClassName('fixed_footer')[0].offsetHeight;
        _this.height = windowHeight - headerHeight - footerHeight;
        _this.chatingHeight = windowHeight - headerHeight - footerHeight + 'px';
        _this.$nextTick(() => {
          _this.$refs.end.scrollIntoView(false);
        })
        // _this.$nextTick(() => {
        //   _this.$refs.content.children[_this.$refs.content.children.length - 1].scrollIntodiv(false);
        // })
      }
    },
    onBack() {
      this.$router.push('/home');
    },
    // 查询订单状态
    selectByOrderId(orderId) {
      let params = {
        orderId: orderId
      }
      this.$http('get', '/server/order/selectByOrderId', params, 1).then(res => {
        if (res.data.code == 200) {
          // console.log('订单状态', res)
          this.orderInfo = res.data.data;
          this.orderStatus = res.data.data.orderStatus;
          this.isComment = res.data.data.isEstimate == 1 ? false : true; //是否评价  1未评价 2已评价
          if (res.data.data.interrogationName) {

            this.interrogationName = res.data.data.interrogationName;
          }
          let createTime = 0
          if (res.data.data.orderExpireTime) {
            createTime = new Date(res.data.data.orderExpireTime.replace(/-/g, '/')).getTime();
            let nowTime = new Date().getTime();
            let time = createTime - nowTime;
            let hours = Math.ceil(time / 1000 / 60 / 60);
            // console.log('时间戳', hours)
            if (hours <= 1) {
              this.hours = Math.ceil(time / 1000 / 60) + '分钟';
            } else {
              this.hours = hours + '小时';
            }
          } else {
            this.hours = 24 + '小时'
          }
        }
      })
    },
    getDocDetail(doctorId) {
      let params = {
        doctorId: doctorId
      }
      this.$http('get', '/server/doctor/detail', params, 1).then(res => {
        if (res.data.code == 200) {
          this.docDetail = res.data.data;
        }
      })
    },
    computedHeight() {
      let _this = this;
      //变量windowHeight是可视区的高度
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      let headerHeight = document.getElementsByClassName('header')[0].offsetHeight + 40;
      let footerHeight = document.getElementsByClassName('fixed_footer')[0].offsetHeight + 40;
      this.height = windowHeight - headerHeight - footerHeight;
      _this.chatingHeight = windowHeight - headerHeight - footerHeight + 'px';
      let contentId = document.getElementById('contentId')
      end.style.height = 10 + 'px';
      _this.$nextTick(() => {
        _this.$refs.end.scrollIntoView(false);
      })
      _this = this;
      contentId.onscroll = function () {
        //变量windowHeight是可视区的高度
        var scrollTop = contentId.scrollTop || contentId.scrollTop;
        var height = contentId.scrollHeight;
        if (Number(_this.height) + 22 + Number(scrollTop) + 100 >= height) {
          // console.log('到底部l', _this.isBottom);
          _this.isBottom = true;
        } else {
          // console.log('没到底部不滚动')
          _this.isBottom = false;
        }
      }
    },
    chatingRecord(problemId) {
      let params = {
        problemId: problemId
      }
      this.$http('get', '/server/onlineDoctorProblemRecord/getProblemRecordList', params, 1).then(res => {
        // console.log('聊天记录', res);
        if (res.data.code == 200) {
          res.data.data.problemRecordList.map((d) => {
            if (d.msgContentType == 15) {
              d.content = JSON.parse(d.content);
            }
          })
          this.chatRecord = res.data.data.problemRecordList;
          this.expriceNum = res.data.data.currentSeesionCount;
          for (let i = 0; i < this.chatRecord.length; i++) {
            let firstTime = getDate('/', this.chatRecord[0].createTimeTamp, true)
            this.chatRecord[0].time = getTimeText(firstTime);  //格式化时间
            if (i + 1 != this.chatRecord.length) {
              if (this.chatRecord[i + 1].createTimeTamp - this.chatRecord[i].createTimeTamp >= 3 * 60 * 1000) {
                let time = getDate('/', this.chatRecord[i + 1].createTimeTamp, true);
                this.chatRecord[i + 1].time = getTimeText(time)
              }
            }
          }
          // if (i + 1 !== this.problemDetail.content.length) {
          //   if (this.problemDetail.content[i + 1].created_time_ms - this.problemDetail.content[i].created_time_ms >= 3 * 60 * 1000) {
          //     let time = getDate('/', this.problemDetail.content[i + 1].created_time_ms, true);
          //     this.problemDetail.content[i + 1].time = getTimeText(time);  //格式化时间
          //   }
          // }
          this.$nextTick(() => {
            if (!this.isBottom) {
              // this.computedHeight();
              // console.log('第一次进来')
              // // console.log(this.$refs.content.children[this.$refs.content.children.length - 1])
              this.$refs.end.scrollIntoView(false);
              // this.$refs.content.children[this.$refs.content.children.length - 1].style.marginBottom = 50 + 'px';
            }
          })
        }
      })
    },
    sendMsg() {
      if (!this.chatValue) {
        Toast('请输入内容');
        return;
      }
      let userData = JSON.parse(localStorage.getItem('userData')) || '';
      let uuid = userData.memberId + this.doctorId + new Date().getTime();
      let params = {
        sysType: 2, //1药康夫 2在线医生
        toUserType: 2,  //接受者类型  1患者 2医生
        fromUserType: 1, //发送者类型 1患者角色 2医生角色
        fromUserId: userData.memberId, // 用户ID
        toUserId: this.doctorId,  //接受人id
        consultOrderId: this.problemId, //订单id
        msgContent: this.chatValue, //发送内容
        msgContentType: 1, // 1-文字 2-图片 3-语言
        templateType: this.templateType,
        sessionType: 2 // 1回执通知 2会话 3订单通知 4心跳 5关闭 6通知用户已接单 7转单通知 8用户离线通知 11剩余次数通知
      }
      this.$socket.sendSock(params)
      params.content = params.msgContent;
      this.chatRecord.push(params);
      this.$nextTick(() => {
        this.$refs.end.scrollIntoView(false);
        this.$refs.textarea.focus();
        document.documentElement.scrollTop = document.body.scrollHeight;
        document.body.scrollTop = document.body.scrollHeight;
      })
      this.chatValue = '';
    },
    // 发送图片
    upFile() {
      this.$refs.img_file.value = "";
      this.$refs.img_file.dispatchEvent(new MouseEvent("click"));
    },
    getFile() {
      let userData = JSON.parse(localStorage.getItem('userData')) || '';
      // console.log('this.$refs.img_file==>', this.$refs.img_file.files);
      let fileArr = [...this.$refs.img_file.files];
      let fd = new FormData();
      fileArr.forEach(item => {
        if (item.type !== "image/jpeg" &&
          item.type !== "image/png" &&
          item.type !== "image/jpg") {
          Toast('"图片只能上传 PNG、JPG、JPEG 格式!"');
          return;
        } else {
          // files.push(item.file);
          fd.append('multipartFiles', item);
        }
      })
      fd.append('businessType', 4);

      this.$http('post', '/server/uploadImg', fd, 1).then(res => {
        // console.log('上传', res);
        if (res.data.code == 200) {
          for (let i = 0; i < res.data.data.length; i++) {
            let item = {
              content: res.data.data[i],
              sessionType: 2,
              userType: 1
            }
            let uuid = userData.memberId + this.doctorId + new Date().getTime();
            let params = {
              sysType: 2, //1药康夫 2在线医生
              toUserType: 2,  //接受者类型  1患者 2医生
              fromUserType: 1, //发送者类型 1患者角色 2医生角色
              fromUserId: userData.memberId, // 用户ID
              toUserId: this.doctorId,  //接受人id
              consultOrderId: this.problemId, //订单id
              msgContent: res.data.data[i], //发送内容
              msgContentType: 2, // 1-文字 2-图片 3-语言
              templateType: this.templateType,
              sessionType: 2 // 1回执通知 2会话 3订单通知 4心跳 5关闭 6通知用户已接单 7转单通知 8用户离线通知
            }
            this.$socket.sendSock(params)
            params.content = params.msgContent;
            this.chatRecord.push(params);
            setTimeout(() => {
              this.$refs.end.scrollIntoView(false);
            }, 500)
          }
        }
      })
    },
    // 预览
    onPrediv(item) {
      // console.log(item)
      ImagePreview({
        images: [
          item.content
        ],
        onClose() {
        },
      });
    },
    // 播放语言
    playAudio(item) {
      // console.log(item)
      let audio = new Audio();//在VUE中使用audio标签
      audio.src = item.content;//设置audio的src为上面生成的url
      audio.play();//进行播放
      // // console.log(audio.ended)
      // audio.addEventListener('ended', function () {
      //   // if ()
      //   // console.log(audio.ended)
      // }, false)
      // if (audio.paused) {

      //   audio.play();//进行播放
      // } else {
      //   audio.pause();// 这个就是暂停
      // }
    },
    // toEvaluation() { // 去评价
    //   // this.$router.push({ path: '/evaluation', query: { doctor_id: id } })
    //   this.$router.push({ name: 'evaluation', params: { docDetail: this.docDetail, problemId: this.problemId, interrogationName: this.interrogationName } })
    // },
    // 进入医生详情
    onDetail() {
      if (this.orderStatus > 3) {

        this.$router.push({ path: '/doctor_detail', query: { docType: this.docDetail.doctorSource, doctor_id: this.docDetail.doctorId, isClick: true } })
      } else {

        this.$router.push({ path: '/doctor_detail', query: { docType: this.docDetail.doctorSource, doctor_id: this.docDetail.doctorId, hideBtn: true, isClick: true } })
      }
      // if (item.doctorSource == 2) { // 1：药极客 2：春雨
      //   this.$router.push({ path: '/doctor_detail', query: { docType: item.doctorSource, doctor_id: item.doctorId, dataId: item.id } })
      // } else {
      // }
    },
    toVisits() {
      this.$router.replace({ path: '/doctor_detail', query: { docType: 1, doctor_id: this.doctorId, isClick: true } })
    },
    onPlay(item) {
      // this.currentAudio = null;
      if (!this.currentAudio) {
        this.currentAudio = new Audio(item.content);
        this.currentAudio.play();
        this.currentAudio.onended = () => {
          this.currentAudio = null;
        }
      } else {
        // this.currentAudio.pause();

        this.currentAudio = null;
      }
      // var audio = document.getElementsByTagName('audio');
      // // console.log(index)
      // // console.log(audio[index])
      // for (let i = 0; i < audio.length; i++) {
      //   // if (i == index) {
      //   // audio[i].play();
      //   // } else {
      //   audio[i].pause();
      //   // }
      // }

    }
  }
}
</script>
<style lang="less" scoped>
body .iOS {
  position: fixed;
  width: 100%;
}
.record_time {
  padding: .15rem;
  text-align: center;
}
.evaluate_end {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 0.2rem 0.3rem;
  margin: 0.3rem;
  width: calc(100% - 1.2rem);
  p {
    color: #333;
    font-size: 0.28rem;
  }
  button {
    border: 0;
    background: #ffa438;
    width: 1rem;
    height: 0.5rem;
    color: #fff;
    border-radius: 0.2rem;
  }
}
.chating {
  min-height: 100%;
  overflow: hidden;
  background: #f8f8f8;
  .header {
    // width: 100%;
    // position: fixed;
    // z-index: 9;
    // top: 0;
    // left: 0;
    .doctorName {
      background: #fff;
      padding: 0.17rem 0;
      font-size: 0.28rem;
      color: #333;
      display: flex;
      align-items: center;
      justify-content: center;
      /deep/ .van-icon {
        margin-left: 0.1rem;
      }
    }
    .title {
      width: 100%;
      padding: 0.14rem 0;
      background: #fff;
      color: #39b4af;
      text-align: center;
      font-size: 0.24rem;
      border-top: 1px solid #f2f2f2;
      border-bottom: 1px solid #f2f2f2;
      p {
        float: left;
        color: #39b4af;
        margin-left: 0.2rem;
        font-size: 0.24rem;
      }
    }
    .mask {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
    }
    .doc_info {
      display: flex;
      align-items: center;
      background: #fff;
      padding: 0.2rem;
      margin: 0 0.4rem;
      width: calc(100% - 1.2rem);
      box-shadow: 0px 0px 6px 3px rgba(218, 218, 218, 0.5);
      position: fixed;
      top: 34px;
      left: 0;
      z-index: 2;
      img {
        width: 1.14rem;
        height: 1.14rem;
        margin-right: 0.2rem;
        border-radius: 50%;
      }
      .info {
        width: calc(100% - 1.34rem);
        .info_right {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.2rem;
          align-items: center;
          p:nth-child(1) {
            width: 100%;
            display: flex;
            align-items: center;
            span:nth-child(1) {
              font-size: 0.32rem;
              color: #333;
              margin-right: 0.2rem;
            }
            span:nth-child(2) {
              display: inline-block;
              width: calc(100% - 2rem);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 0.28rem;
              color: #777777;
            }
          }
          p:nth-child(2) {
            font-size: 0.28rem;
            color: #afafaf;
          }
        }
        .hospital {
          display: flex;
          align-items: center;
          p:nth-child(1) {
            // width: 1.48rem;
            height: 0.38rem;
            line-height: 0.42rem;
            border: 1px solid #42cec8;
            border-radius: 0.42rem;
            color: #42cec8;
            font-size: 0.24rem;
            padding: 0 0.2rem;
            text-align: center;
            margin-right: 0.12rem;
          }
          p:nth-child(2) {
            color: #555;
            font-size: 0.26rem;
          }
        }
      }
    }
  }
  .content {
    // margin-top: 1.4rem;
    // margin-bottom: 1.4rem;
    padding: 0 0.22rem;
    padding-top: 0.22rem;
    width: calc(100% - 0.44rem);
    overflow: auto;
    .complaint {
      margin: 0 0.44rem;
      padding: 0.34rem 0.3rem;
      width: calc(100% - 1.48rem);
      background: #fff;
      border: 1px solid #d9dce3;
      border-radius: 4px;
      .patient {
        margin-bottom: 0.22rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          font-size: 0.3rem;
          color: #333;
          margin-right: 0.2rem;
        }
        .sex,
        .age {
          color: #666666;
          font-size: 0.28rem;
          margin-right: 0.2rem;
        }
      }
      .complaint_content {
        margin: 0 0.14rem;
        padding: 0.3rem;
        width: calc(100% - 0.88rem);
        background: #f8f8f8;
        border-radius: 3px;
        word-break: break-all;
        p {
          font-size: 0.26rem;
          color: #333;
        }
        .complaint_img {
          display: flex;
          flex-wrap: wrap;
          margin-top: 0.14rem;
          .main_img {
            width: 25%;
            height: 0.8rem;
            img {
              width: 80%;
              height: 100%;
            }
          }
        }
      }
    }
    // text-align: center;
    .title {
      padding: 0.08rem 0rem;
      width: 5.68rem;
      background: #cfcccf;
      color: #fff;
      margin: 0 auto;
      margin-top: 0.22rem;
      border-radius: 4px;
      text-align: center;
      font-size: 0.26rem;
    }
    .user {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      // margin-top: 0.3rem;
      // margin-bottom: 0.3rem;
      margin-left: 0.3rem;
      // padding: 0.2rem 0;
      .describe {
        // width: 4.2rem;
        // height: 1.08rem;
        // width: ;
        display: flex;
        // flex-wrap: wrap;
        align-items: center;
        border-radius: 0.3rem 0rem 0.3rem 0.3rem;
        background: #3798ee;
        padding: 0.16rem 0.43rem;
        padding-right: 0.3rem;
        font-size: 0.28rem;
        // margin-left: 0.3rem;
        color: #fff;
        word-break: break-all;
      }
      .drug_box {
        padding: .24rem;
        text-align: left;
        background: #fff;
        border-radius: 8px;
        .t {
          margin-bottom: .16rem;
          font-size: .28rem;
          color: #333333;
        }
        .drug_item {
          background: #F8F8F8;
          border-radius: .1rem;
          display: flex;
          margin-bottom: .15rem;
          padding: .28rem .18rem;
          .drug_img {
            margin-right: .2rem;
            width: 1.28rem;
            height: .94rem;
          }
          .texts {
            .t1 {
              margin-bottom: .2rem;
              font-size: .28rem;
              color: #333333;
            }
            .ts {
              .money {
                display: inline-block;
                margin-right: .28rem;
                font-weight: bold;
                font-size: .24rem;
                color: #BE3022;
              }
              .unit {
                color: #9B9B9B;
                font-size: .24rem;
              }
            }
          }
        }
        .btns {
          // text-align: right;
          .b {
            margin-left: auto;
            width: 1.4rem;
            height: .5rem;
            line-height: .5rem;
            background: #F2F2F2;
            border-radius: 25px;
            color: #878787;
            font-size: .3rem;
            text-align: center;
          }
        }
      }
      .user_icon {
        height: 1.08rem;
        width: 1.08rem;
        border-radius: 50%;
        margin-left: 0.14rem;
      }
      .img {
        height: 1.6rem;
        width: 1.6rem;
      }
    }
    .doctor {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      background: transparent;
      // margin-top: 0.3rem;
      // margin-bottom: 0.3rem;
      .describe {
        // width: 4.2rem;
        // height: 1.08rem;
        display: flex;
        align-items: center;
        border-radius: 0rem 0.3rem 0.3rem 0.3rem;
        background: #fff;
        // border: 1px solid #42cec8;
        padding: 0.16rem 0.43rem;
        // padding-right: 0.3rem;
        font-size: 0.28rem;
        color: #333333;
        word-break: break-all;
      }
      .drug_box {
        padding: .24rem;
        text-align: left;
        background: #fff;
        border-radius: 8px;
        .t {
          margin-bottom: .16rem;
          font-size: .28rem;
          color: #333333;
        }
        .drug_item {
          background: #F8F8F8;
          border-radius: .1rem;
          display: flex;
          margin-bottom: .15rem;
          padding: .28rem .18rem;
          .drug_img {
            margin-right: .2rem;
            width: 1.28rem;
            height: .94rem;
          }
          .texts {
            .t1 {
              margin-bottom: .2rem;
              font-size: .28rem;
              color: #333333;
            }
            .ts {
              .money {
                display: inline-block;
                margin-right: .28rem;
                font-weight: bold;
                font-size: .24rem;
                color: #BE3022;
              }
              .unit {
                color: #9B9B9B;
                font-size: .24rem;
              }
            }
          }
        }
        .btns {
          // text-align: right;
          .b {
            margin-left: auto;
            width: 1.4rem;
            height: .5rem;
            line-height: .5rem;
            background: #FFA438;
            border-radius: 25px;
            color: #fff;
            font-size: .3rem;
            text-align: center;
          }
        }
      }
      .audio {
        width: 2rem;
        height: 0.44rem;
        border-radius: 0.3rem;
        background: #42cec8;
        text-align: right;
        position: relative;
        img {
          position: absolute;
          top: 0;
          right: 0.1rem;
          text-align: right;
          width: 0.4rem;
          height: 0.4rem;
        }
      }
      .user_icon {
        height: 1.08rem;
        width: 1.08rem;
        border-radius: 50%;
        margin-left: 0.14rem;
        margin-right: 0.14rem;
      }
      .img {
        height: 1.6rem;
        width: 1.6rem;
      }
    }
  }
  .fixed_footer {
    background: #fff;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 0.2rem 0.2rem;
    width: calc(100% - 0.4rem);
    // margin-top: 0.4rem;
  }
  .footer {
    display: flex;
    align-items: center;
    width: 100%;
    .bottom_item {
      width: 100%;
    }
    // div {
    //   width: 100%;
    .footer_btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    img {
      width: 0.56rem;
      height: 0.46rem;
      margin-right: 0.32rem;
    }
    .input {
      //   width: 5rem;
      border-radius: 0.8rem;
      height: 0.36rem;
      flex: 1;
      margin-right: 0.34rem;
      background: #f5f7f8;
      padding: 0.16rem 0.3rem;
      border: 0;
    }
    .input::-webkit-input-placeholder {
      color: #b5b5b5;
      font-size: 0.3rem;
    }
    button {
      background: #42cec8;
      border: 0;
      font-size: 0.28rem;
      color: #fff;
      padding: 0.12rem 0.3rem;
      border-radius: 4px;
    }
    .btn {
      margin-top: 0.2rem;
      // width: 2rem;
    }
  }
  .evaluation {
    .popup {
      .van-icon {
        width: 0.52rem;
        height: 0.52rem;
      }
    }
    .evaluation_header {
      display: flex;
      margin-bottom: 0.68rem;
      justify-content: center;
      align-items: center;
      margin-top: 0.58rem;
      img {
        width: 0.64rem;
        height: 0.64rem;
        border-radius: 50%;
        margin-right: 0.2rem;
      }
      div {
        color: #1a1a1a;
        font-size: 0.32rem;
        font-weight: bold;
      }
    }
    .evaluation_footer {
      padding: 0 0.5rem 0.5rem;
      width: calc(100% - 1rem);
      .health_doctor {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0.26rem;
        .title {
          color: #333333;
          font-size: 0.3rem;
          font-weight: bold;
        }
        .name {
          color: #333333;
          font-size: 0.28rem;
        }
      }
      .evaluation_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.34rem;
        width: 100%;
        .item_type {
          color: #333333;
          font-size: 0.28rem;
        }
        /deep/ .van-icon {
          margin-left: 0.32rem !important;
        }
        // .van-icon:last-child {
        //   margin-right: 0;
        // }
      }
      .textarea {
        margin-top: 0.48rem;
        textarea {
          width: calc(100% - 0.64rem);
          background: #f8f8f8;
          border-radius: 0.8rem;
          padding: 0.24rem 0.32rem;
          border: 0;
        }
      }
    }
    .btn {
      margin-top: 0.48rem;
      width: 100%;
      height: 0.94rem;
      background: #42cec8;
      color: #ffffff;
      font-size: 0.36rem;
      line-height: 0.94rem;
      text-align: center;
    }
  }
  .evaluation_success {
    width: 100%;
    text-align: center;
    img {
      width: 1.32rem;
      height: 1.32rem;
      margin-top: 1.42rem;
    }
    .success {
      color: #1a1a1a;
      font-size: 0.4rem;
      margin-top: 0.4rem;
    }
    .tips {
      color: #818997;
      font-size: 0.3rem;
      margin-top: 0.1rem;
      margin-bottom: 1.64rem;
    }
  }
}
</style>
